import '../scss/app.scss';
import 'unfonts.css';
import Glide from "@glidejs/glide";
import '@glidejs/glide/dist/css/glide.core.css'
import '@glidejs/glide/dist/css/glide.theme.css'
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.css'

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR")
    });
}

document.addEventListener('DOMContentLoaded', (e) => {
    let imageSlider = null;
    let currentSlideIndexForLightbox = 0;
    const modelSelectionLoadMore = document.querySelector('.btn-loadmore');

    if(document.querySelector('.glide-teasers')) {
        new Glide(document.querySelector('.glide-teasers'), {
            type: 'carousel',
            rewind: true,
            gap: 40,
            perView: 1,
            startAt: 0,
            focusAt: 'center',
            peek: {
                before: 10,
                after: window.innerWidth / 2
            },
            breakpoints: {
                1143: {
                    gap: 10,
                    perView: 2,
                    peek: {
                        before: 0,
                        after: 70
                    },
                },
                735: {
                    gap: 10,
                    perView: 1,
                    peek: {
                        before: 0,
                        after: 70
                    },
                }
            }
        }).mount({})
    }

    if(document.querySelector('.glide-images')) {
        imageSlider = new Glide(document.querySelector('.glide-images'), {
            type: 'slider',
            effect: 'fade',
            gap: 0,
            animationDuration: 750,
            autoplay: 3750,
            perView: 1,
        })
        imageSlider.on(['mount.after', 'run'], function () {
            currentSlideIndexForLightbox = imageSlider.index
        })
        imageSlider.mount({})
    }

    if(document.querySelector('.glide-models')) {
        new Glide(document.querySelector('.glide-models'), {
            type: 'carousel',
            perView: 4,
            startAt: 1,
            lookAt: 2,
            gap: 40,
            peek: {
                before: -100,
                after: -100
            },
            breakpoints: {
                1280: {
                    perView: 3
                },
                600: {
                    focusAt: 'center',
                    perView: 1,
                    peek: {
                        before: 0,
                        after: 150
                    },
                }
            }

        }).mount({})
    }

    if(document.querySelector('.glide-news')) {
        new Glide(document.querySelector('.glide-news'), {
            type: 'carousel',
            perView: 4,
            startAt: 1,
            lookAt: 2,
            gap: 40,
            peek: {
                before: -100,
                after: -100
            },
            breakpoints: {
                1280: {
                    perView: 3
                },
                600: {
                    focusAt: 'center',
                    perView: 1,
                    peek: {
                        before: 0,
                        after: 150
                    },
                }
            }

        }).mount({})
    }

    if(document.querySelector('.thumb-grid')) {
        const galleryElements = JSON.parse(document.querySelector('.thumb-grid').getAttribute('data-gallery'));
        const lightboxArray = [];

        // PREPARE ITEMS FOR LIGHTBOX
        galleryElements.forEach((element, index) => {
            lightboxArray.push({
                'href': element
            })
        });

        const lightbox =  GLightbox({
            elements: lightboxArray,
            touchNavigation: true,
            loop: true,
            autoplayVideos: true
        });

        document.querySelector('.glide__track').addEventListener('click', (e) => {
            lightbox.openAt(currentSlideIndexForLightbox);
        });
        // THUMB LIGHTBOX
        document.querySelector('.thumb-grid').addEventListener('click', (e) => {
            if(e.target.attributes[1].value) {
                lightbox.openAt(e.target.attributes[1].value);
            } else {
                lightbox.open();
            }
        })
    }

    if(document.querySelector('.tab-switcher')) {
        modelNavigator();

        htmx.on('htmx:afterSwap', function(e) {
            modelNavigator()
        })
    }

    if(document.querySelector('details')) {
        // Fetch all the details element.
        const details = document.querySelectorAll("details");

// Add the onclick listeners.
        details.forEach((detail) => {
            detail.addEventListener("toggle", () => {
                if (detail.open) setTargetDetail(detail);
            });
        });

// Close all the details that are not targetDetail.
        function setTargetDetail(targetDetail) {
            details.forEach((detail) => {
                if (detail !== targetDetail) {
                    detail.open = false;
                }
            });
        }
    }

    if(modelSelectionLoadMore) {
        modelSelectionLoadMore.addEventListener('click', (e) => {

        })
    }
});

function modelNavigator() {
    let tabSwitcher = document.querySelector('.tab-switcher');
    let tabs = tabSwitcher.querySelectorAll('.tab');
    let tabContent = document.querySelectorAll('.tab-content');

    tabs.forEach((tab) => {
        tab.addEventListener('click', (e) => {
            tabs.forEach((element) => {element.classList.remove('active');});
            tabContent.forEach((element) => {element.classList.remove('active');});

            e.currentTarget.classList.add('active');

            tabContent[e.currentTarget.attributes[1].value - 1].classList.add('active');
        })
    })
}
